<template>
  <v-card class="ma-3">
    <v-card-title>
      {{ event.name }}
    </v-card-title>
    <v-divider />
    <v-card-text>
      <event-info-list :event="event"></event-info-list>
    </v-card-text>
    <v-divider />
    <v-card-title>
      Eigene Angaben
      <v-btn
        icon
        class="ml-2"
        @click="toggleEditSave()"
        v-if="registrationContentEditable"
      >
        <v-icon>
          {{ registrationEditable ? "save" : "edit" }}
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-card-title>Stammdaten und Notfallkontakte</v-card-title>
      <v-card-subtitle
        >Deine persönlichen Daten und deine Notfallkontakte können in deinem
        persönlichen Bereich eingesehen werden.
        <a @click="$router.push('/personal')"
          >&rarr; Persönlicher Bereich</a
        ></v-card-subtitle
      >
      <registration-editor
        ref="registrationEditor"
        :event-id="event.id"
        :editable="registrationEditable"
        :radio-button-group-row="$vuetify.breakpoint.mdAndUp"
      />
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-btn
        icon
        class="ml-2"
        @click="toggleEditSave()"
        v-if="registrationContentEditable"
      >
        <v-icon>
          {{ registrationEditable ? "save" : "edit" }}
        </v-icon>
      </v-btn>
      <v-btn color="warning" v-if="unregister()">
        Abmelden
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import EventInfoList from "@/components/events/EventInfoList";
import RegistrationEditor from "@/components/registration/RegistrationEditor";
import { mapActions } from "vuex";
export default {
  name: "RegistrationDetail",
  components: { RegistrationEditor, EventInfoList },
  computed: {
    event() {
      let index = this.$store.state.events.events
        .map(event => event.id)
        .indexOf(parseFloat(this.$route.params.eventId));
      return this.$store.state.events.events[index];
    },
    registrationContentEditable() {
      return (
        this.event.registrationInformation.registrationModuleDiet ||
        this.event.registrationInformation.registrationModuleSwimming ||
        this.event.registrationInformation.registrationModuleDiet ||
        this.event.registrationInformation.registrationModuleMedicalData
      );
    }
  },
  data() {
    return {
      registrationEditable: false
    };
  },
  methods: {
    ...mapActions("events", ["unregister"]),
    updateRegistrationData() {
      let successful = this.$refs.registrationEditor.updateRegistrationData();
      if (successful) {
        this.registrationEditable = false;
      }
    },
    toggleEditSave() {
      if (this.registrationEditable) {
        this.updateRegistrationData();
      } else {
        this.registrationEditable = true;
      }
    }
  }
};
</script>

<style scoped></style>
