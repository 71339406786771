-<template>
  <v-row class="justify-center mx-0">
    <v-card width="3000" flat>
      <v-card-title>
        Anmeldung <span class="ml-2">{{ event.name }}</span>
      </v-card-title>
      <registration-stepper class="registration-stepper" :event-id="event.id" />
    </v-card>
  </v-row>
</template>

<script>
import RegistrationStepper from "../components/registration/RegistrationStepper";
import { mapActions } from "vuex";

export default {
  name: "Registration",
  components: {
    "registration-stepper": RegistrationStepper
  },
  computed: {
    event() {
      let index = this.$store.state.events.events
        .map(event => event.id)
        .indexOf(parseInt(this.$route.params.eventId));
      return this.$store.state.events.events[index];
    }
  },
  methods: {
    ...mapActions("snackbar", ["showSnackbar"])
  },
  mounted() {
    // if registration for this event exists goto event overview
    if (
      this.$store.state.events.registrations
        .map(reg => reg.eventId)
        .includes(this.event.id)
    ) {
      this.$router.replace("/events");
    }
    this.$store.subscribe(mutation => {
      if (mutation.type === "events/finishRegistration") {
        this.showSnackbar({
          text: "Anmeldung Abgeschlossen",
          color: "success",
          timout: "10000"
        });
        this.$router.push("/events");
      }
    });
  }
};
</script>

<style scoped>
.v-stepper {
  box-shadow: initial;
  -webkit-box-shadow: initial;
}
/*  .v-card__title { //title fixed
    width: -moz-available;
    width: available;
    position: fixed;
    z-index: 1;
  }
  .registration-stepper {
    margin-top: 50px;
  }
  */
</style>
